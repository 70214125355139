import { useForm } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";

import { BASE_URL } from "../../constants/requests";
import Layout from "../../components/layout";

const REQUEST_URL = `${BASE_URL}/accounts/change_password`;

function ChangePassword() {
    const [netError, setNetError] = useState("");
    const [done, setDone] = useState(false);
    const [working, setWorking] = useState(false);
    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        watch
    } = useForm();

    const password = useRef(null);
    password.current = watch("password", "");

    useEffect(() => {
        reset();
    }, [done, reset]);

    const onSubmit = async data => {
        try {
            setNetError("");
            setWorking(true);

            const urlSearchParams = new URLSearchParams(window.location.search);
            if (!urlSearchParams.has("token")) {
                setNetError("Missing reset token.");

                reset();

                return;
            }

            const token = urlSearchParams.get("token");
            const body = { confirm_password: data.passwordConfirm, password: data.password, token };
            const response = await fetch(REQUEST_URL, {
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PUT"
            });

            if (response.status === 204) {
                setDone(true);
            } else {
                setNetError("Unable to change password. Please refresh the page and try again.");
            }
        } catch (error) {
            setNetError(error.message);
        } finally {
            setWorking(false);
        }
    };

    const renderDone = () => (
        <div className="uk-text-center">
            <h3>Your password has been changed.</h3>
        </div>
    );

    const renderForm = () => (
        <form className="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="uk-fieldset">
                <h3>Change Password</h3>

                {netError && <div className="uk-alert uk-alert-danger">{netError}</div>}

                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="email">
                        Password
                    </label>

                    <div className="uk-form-controls">
                        <input
                            className={`uk-input uk-form-large ${errors.password && "uk-form-danger"}`}
                            id="password"
                            type="password"
                            {...register("password", {
                                maxLength: {
                                    message: "Password may not exceed 32 characters",
                                    value: 32
                                },
                                minLength: {
                                    message: "Password must be at least 8 characters",
                                    value: 8
                                },
                                required: "Password is required"
                            })}
                        />
                        {errors.password && <div className="uk-text-danger">{errors.password.message}</div>}
                    </div>
                </div>

                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="email">
                        Password Confirmation
                    </label>

                    <div className="uk-form-controls">
                        <input
                            className={`uk-input uk-form-large ${errors.passwordConfirm && "uk-form-danger"}`}
                            id="passwordConfirm"
                            type="password"
                            {...register("passwordConfirm", {
                                validate: value =>
                                    value === password.current || "Confirmation password does not match password"
                            })}
                        />
                        {errors.passwordConfirm && (
                            <div className="uk-text-danger">{errors.passwordConfirm.message}</div>
                        )}
                    </div>
                </div>

                <div className="uk-margin">
                    <button
                        className="button-pink uk-button uk-button-primary"
                        disabled={working}
                        style={{ width: "320px" }}
                        type="submit"
                    >
                        {working ? "Working..." : "Change Password"}
                    </button>
                </div>
            </fieldset>
        </form>
    );

    return (
        <Layout className="uk-margin-large" style={{ height: "470px" }}>
            <div className="uk-section">
                <div className="uk-container uk-container-medium">{done ? renderDone() : renderForm()}</div>
            </div>
        </Layout>
    );
}

export default ChangePassword;
